import React from "react";
import HorizontalTimeline from "react-horizontal-timeline";


const VALUES = [];

const EXAMPLE = [
	"2020-01-10",
	"2020-01-11"
  ];

export default class Timeline extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          curIdx: 0,
          prevIdx: -1
        };
      }
    
      //state = { value: 0, previous: 0 };
    
      render() {
        const { curIdx, prevIdx } = this.state;
        const curStatus = EXAMPLE[curIdx].statusB;
        const prevStatus = prevIdx >= 0 ? EXAMPLE[prevIdx].statusB : "";
    
        return (
			<div>
				{/* Bounding box for the Timeline */}
				<div className="cursos">
					<HorizontalTimeline
						styles={{
							background: "#f8f8f8",
							foreground: "#1A79AD",
							outline: "#dfdfdf"
						}}
						index={this.state.curIdx}
						indexClick={(index) => {
							const curIdx = this.state.curIdx;
							this.setState({ curIdx: index, prevIdx: curIdx });
						}}
						values={EXAMPLE.map((x) => x.data)}
					/>
				</div>
			</div>
        );
      }
    }


import React from "react";
import {Consumer} from "../context";
import {withRouter} from "react-router-dom";

import Timeline from "../components/timeline";
import Logo from "../images/cropped-Annia-Logo01.png";
import Mc4 from "../images/mc4.svg";
import Mc1 from "../images/mc1.svg";
import Mc2 from "../images/mc2.svg";
import Mc3 from "../images/mc3.svg";
import ReactNav from "../images/react-native_1x-3.svg";
import Swift from "../images/swiftui14-cover.svg";
import UI from "../images/ui-design.svg";
import Flutter from "../images/flutter-cover.svg";
import Promo from "../images/Create_a_Promo_Video_in_AE.svg";




class Curso1 extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	render() {
		return (
			<div className="row full cursos">
				<div class="column full align-center">
					<div class="container column">
						<header class="row justify-center align-center" id="navbar">
							<div class="full justify-between">
								<div class="logo align-center row">
									<a href="#init" class="responsive-img auto">
										<img src={Logo} alt="Logo" title="Logo" />
									</a>
								</div>
								<nav class="align-center justify-end">
									<div class="nav-icon" id="nav-iconburg">
										<div></div>
									</div>

									<div class="row main" id="main-cont">
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-lightgray"> Home </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-lightgray"> Lorem ipsum </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-black"> Lorem ipsum </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-black"> Lorem ipsum </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-black"> Lorem ipsum </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-black"> Lorem ipsum </a>
										</div>
										<div class="nav-item auto">
											<a href="#init" class="font-regular color-black"> Lorem ipsum </a>
										</div>
									</div>
								</nav>
							</div>
						</header>
				</div>
				<div class="section-2 row full justify-center align-center">
					<div class="container column justify-center">
						<div class="white-space-64"></div>
							<Timeline />
						<div class="white-space-64"></div>
						<div class="white-space-64"></div>
						<div class="row justify-center align-center">
						<div class="column justify-center align-center">
								
						</div>
							<div class="column justify-center align-center">
							<div class="container">
								<div class="curso-2 title">
									<h1 class="title">DEFINAMOS TU GRUPO<br/> DE COLOR</h1>
									<div class="white-space-16"></div>
									<h3 class="subtitle">Pregunta 1:</h3>
									<div class="white-space-16"></div>
									<h4 class="subtitle">Al observar tu venas. Sueles verlas</h4>
								</div>
							</div>
							</div>
							<div class="column justify-center align-center">
								
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g4 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Flutter} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Verdosas</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g1 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Promo} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Azuladas</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="white-space-64"></div>
					</div>
				</div>
				<div class="section-3 row full justify-center align-center">
					<div class="container column justify-center">
						<div class="white-space-64"></div>
						<div class="row justify-center align-center">
						<div class="column justify-center align-center">
								
						</div>
							<div class="column justify-center align-center">
							<div class="container">
								<div class="curso-2 title">
									<h1 class="title">DEFINAMOS TU GRUPO<br/> DE COLOR</h1>
									<div class="white-space-16"></div>
									<h3 class="subtitle">Pregunta 2:</h3>
									<div class="white-space-16"></div>
									<h4 class="subtitle">Al exponerte al sol Sueles ponerte</h4>
								</div>
							</div>
							</div>
							<div class="column justify-center align-center">
								
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g4 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Flutter} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Bronceada</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g1 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Promo} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Rojiza</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="white-space-64"></div>
					</div>
				</div>
				<div class="section-2 row full justify-center align-center">
					<div class="container column justify-center">
						<div class="white-space-64"></div>
						<div class="row justify-center align-center">
						<div class="column justify-center align-center">
								
						</div>
							<div class="column justify-center align-center">
							<div class="container">
								<div class="curso-2 title">
									<h1 class="title">DEFINAMOS TU GRUPO<br/> DE COLOR</h1>
									<div class="white-space-16"></div>
									<h3 class="subtitle">Pregunta 3:</h3>
									<div class="white-space-16"></div>
									<h4 class="subtitle">Al teñirte el pelo Sueles ponerse</h4>
								</div>
							</div>
							</div>
							<div class="column justify-center align-center">
								
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g4 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Flutter} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Dorado o anaranjado</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g1 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Promo} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Cenizo o verdoso</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="white-space-64"></div>
					</div>
				</div>
				<div class="section-3 row full justify-center align-center">
					<div class="container column justify-center">
						<div class="white-space-64"></div>
						<div class="row justify-center align-center">
						<div class="column justify-center align-center">
								
						</div>
							<div class="column justify-center align-center">
							<div class="container">
								<div class="curso-2 title">
									<h1 class="title">DEFINAMOS TU GRUPO<br/> DE COLOR</h1>
									<div class="white-space-16"></div>
									<h3 class="subtitle">Pregunta 4:</h3>
									<div class="white-space-16"></div>
									<h4 class="subtitle">Al sonrojarte, Tus pomulos sueles ponerse</h4>
								</div>
							</div>
							</div>
							<div class="column justify-center align-center">
								
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g4 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Flutter} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Durazno</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
							<div class="column justify-center align-center">
								<div class="container">
									<div class="card-img bg-g1 full column justify-center align-center">
										<div class="responsive-img">
											<img src={Promo} alt="" />
										</div>
										<div class="row text-center">
											
										</div>
										<div class="white-space-16"></div>
										<div class="white-space-16"></div>
										<div class="row text-center">
											<p class="color-white font-regular">Rosados</p>
										</div>
										<div class="white-space-8"></div>
									</div>
								</div>
							</div>
						</div>
						<div class="white-space-64"></div>
						<div class="white-space-64"></div>
					</div>
				</div>
				<div class="section row"></div>

				<h1 className="weight-light color-white">Curso 1</h1>						

					
					<h1>Curso </h1>						
					<h1>Cursos Juancho</h1>
					<h2>Price $25.00</h2>	
					
					<form method="POST" action="http://localhost:8081/pay">
						<input type="submit" value="Comprar" />			
					</form>						
					<div className="white-space-32"></div>	
			</div>
			</div>
		);
	}
}

export default withRouter(Consumer(Curso1));
import React, { useEffect, useState } from 'react';
import Logo from "../images/cropped-Annia-Logo01.png";
import Flutter from "../images/flutter-cover.svg";
import Timeline from "../components/timeline";
import ReactNav from "../images/react-native_1x-3.svg";

const PreguntasBloque1 = ({respuestas, sendRespuestas}) => {
	function setRespuesta(id_pregunta, resp) {
		let bloque = respuestas.bloque1;
		if (!bloque) {
			bloque = [];
		}
		bloque[id_pregunta] = resp;
		respuestas.bloque1 = bloque;

		sendRespuestas(respuestas);
	}

	return (
		<div className="column full">
			<div className="">
				<h1 className="title">DEFINAMOS TU GRUPO <br/> DE COLOR</h1>
			</div>
				<Preguntas
					id_pregunta		=	{1}
					pregunta		=	"Al observar tus venas sueles verlas"
					respuestas		=	{[{opcion: 'a', valor: '1', texto:'Verdosas'},{opcion: 'b', valor: '2', texto:'Azuladas'}]}
					setRespuestas	=	{setRespuesta.bind(this)}
					/>

				<Preguntas
					id_pregunta		=	{2}
					pregunta		=	"Al Exponerte al Sol, tiendes a ponerte"
					respuestas		=	{[{opcion: 'a', valor: '1', texto:'Bronceada'},{opcion: 'b', valor: '2', texto:'Rojiza'}]}
					setRespuestas	=	{setRespuesta.bind(this)}
					/>

				<Preguntas
					id_pregunta		=	{3}
					pregunta		=	"Al teñirte el Pelo suele ponerse"
					respuestas		=	{[{opcion: 'a', valor: '1', texto:'Dorado o anaranjado'},{opcion: 'b', valor: '2', texto:'Cenizo o verdoso'}]}
					setRespuestas	=	{setRespuesta.bind(this)}
					/>

				<Preguntas
					id_pregunta		=	{4}
					pregunta		=	"Al sonrojarte tus pómulos se ven"
					respuestas		=	{[{opcion: 'a', valor: '1', texto:'Durazno'},{opcion: 'b', valor: '2', texto:'Rosados'}]}
					setRespuestas	=	{setRespuesta.bind(this)}
				/>
		</div>
	)
}

const ResultadoBloque1 = ({setBloque}) => {
	return (
		<div class="row full justify-center align-center">
			<div class="column full justify-center align-center">
				<div class="container justify-center align-center row">
					<div class="column full">
						<div class="row">
							<h2 class="title weight-bold">DEFINAMOS TU GRUPO DE COLOR</h2>
						</div>
						<div class="row">
							<div class="white-space-16"></div>
						</div>
						<div class="row">
							<h2 class="subtitle">Resultado:</h2>
						</div>
						<div class="row">
							<div class="white-space-16"></div>
						</div>
						<div class="row">
							<h2 class="subtitle font-medium">
								Tu perteneces al Grupo de color Calido.
							</h2>
						</div>
						<div class="row">
							<div class="white-space-16"></div>
						</div>
							<button className="btn" onClick={() => setBloque(2)}>
								CONTINUAR
							</button>
					</div>
				</div>
			</div>
			<div class="column full justify-center align-center">
				<div class="semi-full justify-center align-center">
					<div class="card-list full justify-center">
						<div class="column full align-center justify-center">
							<div class="container">
								<div class="card-img bg-g1 full column justify-center align-center">
									<div class="responsive-img">
										<img src={ReactNav} alt="" />
									</div>
									<div class="white-space-16"></div>
									<div class="row text-center">
										<h3 class="subtitle">Tu perteneces al Grupo de color Dependiendo el seleccionado anteriormente</h3>
									</div>
									<div class="white-space-16"></div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="white-space-24"></div>
			</div>
		</div>
	)
}

const PreguntasBloque2 = ({tipo, respuestas, sendRespuestas, setBloque}) => {

	function setRespuesta(id_pregunta, resp) {
		respuestas.bloque2 = resp;

		sendRespuestas(respuestas);

		setBloque(3);
	}

	return (
		<div className="column full">
			<h1 className="title">RECONOCE EL <br/> CONTRASTE DE TU PIEL</h1>
			{tipo == 'a' &&
				<Preguntas
					id_pregunta		=	{1}
					pregunta		=	"Tus ojos, cejas y pelo tienen ?"
					respuestas		=	{[
											{opcion: 'otoño', valor: '1', texto:'Son más oscuro y con contrastes  en relación con mi color de piel '},
											{opcion: 'primavera', valor: '2', texto:'Son claros como mi tono de piel'},
											{opcion: 'otoño', valor: '3', texto:'Mis ojos son claros pero mi pelo y cejas son mas oscuros'}
										]}
					setRespuestas	=	{setRespuesta.bind(this)}/>
			}
			{tipo == 'b' &&
				<Preguntas
					id_pregunta		=	{1}
					pregunta		=	"Tus ojos, cejas y pelo tienen ?"
					respuestas		=	{[
											{opcion: 'invierno', valor: '1', texto:'Son más oscuro o claros  y con contrastes en relación con mi color de piel'},
											{opcion: 'verano', valor: '2', texto:'Son claros como mi tono de piel'},
											{opcion: 'invierno', valor: '3', texto:'Mis ojos son claros pero mi pelo y cejas son oscuro'},
											{opcion: 'invierno', valor: '4', texto:'Mis ojos son oscuros pero mi pelo y cejas han aclarado por las canas'}
										]}
					setRespuestas	=	{setRespuesta.bind(this)}/>
			}
		</div>
	)
}

const PreguntasBloque3 = ({tipo, tipo2, respuestas, sendRespuestas}) => {
	function setRespuesta(id_pregunta, resp) {
		let bloque = respuestas.bloque3;
		if (!bloque) {
			bloque = [];
		}
		bloque[id_pregunta] = resp;
		respuestas.bloque3 = resp;

		sendRespuestas(respuestas);
	}

	return (
		<div className="column full">
			<h1 className="title">DESCUBRE TU SUBPALETA DE COLOR</h1>
			<h1>{tipo2}</h1>
			{tipo == 'a' &&
			<>
				<Preguntas2
					id_pregunta		=	{1}
					pregunta		=	"El Color base de piel es ?"
					respuestasA		=	{tipo2 == 'otoño'? [
											{opcion: 'a', valor: '1', texto:'Marrones'},
											{opcion: 'a', valor: '1', texto:'Oliváceas'},
											{opcion: 'a', valor: '1', texto:'Beige Doradas'}
										]: tipo2 == 'primavera'? [
											{opcion: 'a', valor: '1', texto:'Porcelana Luminosa'},
											{opcion: 'a', valor: '1', texto:'Marfíl luminosa'},
											{opcion: 'a', valor: '1', texto:'Beige Trigo Luminosa'},
											{opcion: 'a', valor: '1', texto:'Beige Claro Luminosa'}
										]: []}
					respuestasB		=	{tipo2 == 'otoño'? [
											{opcion: 'b', valor: '2', texto:'Trigo'},
										]: tipo2 == 'primavera'? [
											{opcion: 'b', valor: '2', texto:'Durazno'},
											{opcion: 'b', valor: '2', texto:'Porcelana Dorada'},
											{opcion: 'b', valor: '2', texto:'Trigo'},
											{opcion: 'b', valor: '2', texto:'Marfíl'}
										]: []}
					respuestasC		=	{tipo2 == 'otoño'? [
											{opcion: 'c', valor: '3', texto:'Beige neutro'},
											{opcion: 'c', valor: '3', texto:'Beige neutro Claro'},
											{opcion: 'c', valor: '3', texto:'Beige neutro medio'},
											{opcion: 'c', valor: '3', texto:'Marfíl'}
										]: tipo2 == 'primavera'? [
											{opcion: 'c', valor: '3', texto:'Porcelana Luminosa'},
											{opcion: 'c', valor: '3', texto:'Marfíl luminosa'},
											{opcion: 'c', valor: '3', texto:'Porcelana con tinte durazno'},
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{2}
					pregunta		=	"El color de pelo natural es?"
					respuestasA		=	{tipo2 == 'otoño'? [
											{opcion: 'a', valor: '1', texto:'Negros'},
											{opcion: 'a', valor: '1', texto:'Castaño Oscuro'},
											{opcion: 'a', valor: '2', texto:'Castaño medio dorado'}
										]: tipo2 == 'primavera'? [
											{opcion: 'a', valor: '1', texto:'Rubio Oscuro'},
											{opcion: 'a', valor: '1', texto:'Rubios medios'},
											{opcion: 'a', valor: '1', texto:'Rubios Claros'},
											{opcion: 'a', valor: '1', texto:'Reflejos dorados'}
										]: []}
					respuestasB		=	{tipo2 == 'otoño'? [
											{opcion: 'b', valor: '2', texto:'Pelirrojo'},
											{opcion: 'b', valor: '2', texto:'Castaño rojizo'},
											{opcion: 'b', valor: '2', texto:'Castaño medio dorado'},
											{opcion: 'b', valor: '2', texto:'Reflejos Rojizos'},
										]: tipo2 == 'primavera'? [
											{opcion: 'b', valor: '2', texto:'Rubio Dorado Rojizo'},
											{opcion: 'b', valor: '2', texto:'Rubios Dorados con toques naranja'},
											{opcion: 'b', valor: '2', texto:'Pelirroja Dorada'}
										]: []}
					respuestasC		=	{tipo2 == 'otoño'? [
											{opcion: 'c', valor: '3', texto:'Marrón medio'},
											{opcion: 'c', valor: '3', texto:'Castaño Claro'},
											{opcion: 'c', valor: '3', texto:'Dorado'},
											{opcion: 'c', valor: '3', texto:'Rubio Dorado medio'}
										]: tipo2 == 'primavera'? [
											{opcion: 'c', valor: '3', texto:'Rubios Dorados Claros'},
											{opcion: 'c', valor: '3', texto:'Grises Claros Dorados'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{3}
					pregunta		=	"Color de ojos"
					respuestasA		=	{tipo2 == 'otoño'? [
											{opcion: 'a', valor: '1', texto:'Marrones oscuro'},
											{opcion: 'a', valor: '1', texto:'Verde Oscuro'},
											{opcion: 'a', valor: '1', texto:'Avellana Oscuro'}
										]: tipo2 == 'primavera'? [
											{opcion: 'a', valor: '1', texto:'Azules'}, 
											{opcion: 'a', valor: '1', texto:'verdes'},
											{opcion: 'a', valor: '1', texto:'Avellanas Claros'}
										]: []}
					respuestasB		=	{tipo2 == 'otoño'? [
											{opcion: 'b', valor: '2', texto:'Marrones'},
											{opcion: 'b', valor: '2', texto:'Verde oliva'},
											{opcion: 'b', valor: '2', texto:'Almendra oscura'},
										]: tipo2 == 'primavera'? [
											{opcion: 'b', valor: '2', texto:'Verdes Cálidos'},
											{opcion: 'b', valor: '2', texto:'Amarillos'}, 
											{opcion: 'b', valor: '2', texto:'Azules'},
											{opcion: 'b', valor: '2', texto:'Turquesa'},
											{opcion: 'b', valor: '2', texto:'Avellana Claro'},
											{opcion: 'b', valor: '2', texto:'Avellana Brillante'}
										]: []}
					respuestasC		=	{tipo2 == 'otoño'? [
											{opcion: 'c', valor: '3', texto:'Marrón Claro'},
											{opcion: 'c', valor: '3', texto:'Verde'},
											{opcion: 'c', valor: '3', texto:'Azul'},
											{opcion: 'c', valor: '3', texto:'Avellana Claro'}
										]: tipo2 == 'primavera'? [
											{opcion: 'c', valor: '3', texto:'Verdes suaves luminosos'},
											{opcion: 'c', valor: '3', texto:'Azules verdosos Luminosos'},
											{opcion: 'c', valor: '3', texto:'Azules luminosos'},
											{opcion: 'c', valor: '3', texto:'Agua'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{4}
					pregunta		=	"Tus rasgos son similares a los de? "
					respuestasA		=	{tipo2 == 'otoño'? [
											{opcion: 'a', valor: '1', texto:'Hally Berry'},
											{opcion: 'a', valor: '1', texto:'Beyonce'},
											{opcion: 'a', valor: '1', texto:'Rihana'},
											{opcion: 'a', valor: '1', texto:'Salma hayek'},
											{opcion: 'a', valor: '1', texto:'Eva Mendez'},
											{opcion: 'a', valor: '1', texto:'Michelle Rodriguez'}
										]: tipo2 == 'primavera'? [
											{opcion: 'a', valor: '1', texto:'Jessica Biel'},
											{opcion: 'a', valor: '1', texto:'Jennifer Aniston'},
											{opcion: 'a', valor: '1', texto:'Kristin Kreuk'},
											{opcion: 'a', valor: '1', texto:'Olivia Wilde'}
										]: []}
					respuestasB		=	{tipo2 == 'otoño'? [
											{opcion: 'b', valor: '2', texto:'Jessica Alba'},
											{opcion: 'b', valor: '2', texto:'Juliet Robert'},
											{opcion: 'b', valor: '2', texto:'Angelina Joli'}
										]: tipo2 == 'primavera'? [
											{opcion: 'b', valor: '2', texto:'Amy Adams'},
											{opcion: 'b', valor: '2', texto:'Lindsay Lohan'},
											{opcion: 'b', valor: '2', texto:'Isla Fisher'},
											{opcion: 'b', valor: '2', texto:'Key Mara'},
											{opcion: 'b', valor: '2', texto:'Jessica Chastain'}
										]: []}
					respuestasC		=	{tipo2 == 'otoño'? [
											{opcion: 'c', valor: '3', texto:'Jennifer Lopez'},
											{opcion: 'c', valor: '3', texto:'Sofia Vergara'},
											{opcion: 'c', valor: '3', texto:'Tyra Banks'},
											{opcion: 'c', valor: '3', texto:'Irina Shayk'},
											{opcion: 'c', valor: '3', texto:'Barbara Mori'},
											{opcion: 'c', valor: '3', texto:'Vanessa Williams'}
										]: tipo2 == 'primavera'? [
											{opcion: 'c', valor: '3', texto:'Giselle Bundchen'},
											{opcion: 'c', valor: '3', texto:'Paris Hilton'},
											{opcion: 'c', valor: '3', texto:'Charlize Theron'},
											{opcion: 'c', valor: '3', texto:'Heidi Klum'},
											{opcion: 'c', valor: '3', texto:'Blake Lively'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>
			</>
			}
			{tipo == 'b' &&
			<>
				<Preguntas2
					id_pregunta		=	{1}
					pregunta		=	"El Color base de piel es ?"
					respuestasA		=	{tipo2 == 'invierno'? [
											{opcion: 'a', valor: '2', texto:'Beige neutro Pálido'},
											{opcion: 'a', valor: '2', texto:'Beige Claro Ceniza'},
											{opcion: 'a', valor: '2', texto:'Beige Rosa Claro'},
											{opcion: 'a', valor: '2', texto:'Porcelana'},
											{opcion: 'a', valor: '1', texto:'Oliváceo Claro'},
											{opcion: 'a', valor: '1', texto:'Negra azulada'}
										]: tipo2 == 'verano'? [
											{opcion: 'a', valor: '1', texto:'Rosado Suave'},
											{opcion: 'a', valor: '1', texto:'Beige'},
											{opcion: 'a', valor: '1', texto:'Beige Ceniza'}
										]: []}
					respuestasB		=	{tipo2 == 'invierno'? [
											{opcion: 'b', valor: '2', texto:'Beige neutro Pálido'},
											{opcion: 'b', valor: '2', texto:'Beige Claro Ceniza'},
											{opcion: 'b', valor: '2', texto:'Beige Rosa Claro'},
											{opcion: 'b', valor: '2', texto:'Porcelana'}
										]: tipo2 == 'verano'? [
											{opcion: 'b', valor: '2', texto:'Marfíl'},
											{opcion: 'b', valor: '2', texto:'Beige Claro'}
										]: []}
					respuestasC		=	{tipo2 == 'invierno'? [
											{opcion: 'c', valor: '3', texto:'Oliváceas'},
											{opcion: 'c', valor: '3', texto:'Beige Claro'},
											{opcion: 'c', valor: '3', texto:'trigo'},
											{opcion: 'c', valor: '1', texto:'Negra azulada'},
										]: tipo2 == 'verano'? [
											{opcion: 'c', valor: '3', texto:'porcelana luminosa'},
											{opcion: 'c', valor: '3', texto:'Beige muy clara'},
											{opcion: 'c', valor: '3', texto:'Blanca'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{2}
					pregunta		=	"El color de pelo natural es?"
					respuestasA		=	{tipo2 == 'invierno'? [
											{opcion: 'a', valor: '1', texto:'Negro'},
											{opcion: 'a', valor: '1', texto:'Negro Azulado'},
											{opcion: 'a', valor: '1', texto:'Negro Cenizo'},
										]: tipo2 == 'verano'? [
											{opcion: 'a', valor: '1', texto:'castaño claro ceniza canosos'},
											{opcion: 'a', valor: '1', texto:'Rubio Ceniza'},
											{opcion: 'a', valor: '1', texto:'Pelirrojas naturales claros'},
											{opcion: 'a', valor: '1', texto:'Canosos'}
										]: []}
					respuestasB		=	{tipo2 == 'invierno'? [
											{opcion: 'b', valor: '2', texto:'Castaño Ceniza'},
											{opcion: 'b', valor: '2', texto:'Chocolate con reflejos Caramelo'},
											{opcion: 'b', valor: '2', texto:'Rubio oscuro Ceniza'},
											{opcion: 'b', valor: '2', texto:'Oscuro Con Cana'},
											{opcion: 'b', valor: '2', texto:'Canoso'}
										]: tipo2 == 'verano'? [
											{opcion: 'b', valor: '2', texto:'Rubio ceniza'},
											{opcion: 'b', valor: '2', texto:'Castaño Medio'},
											{opcion: 'b', valor: '2', texto:'Gris Plomo'}
										]: []}
					respuestasC		=	{tipo2 == 'invierno'? [
											{opcion: 'c', valor: '2', texto:'Castaño Ceniza'},
											{opcion: 'c', valor: '3', texto:'Castaño Oscuro'}
										]: tipo2 == 'verano'? [
											{opcion: 'c', valor: '3', texto:'Rubio ceniza muy claro'},
											{opcion: 'c', valor: '3', texto:'Blanco'},
											{opcion: 'c', valor: '3', texto:'Plateado'},
											{opcion: 'c', valor: '3', texto:'Canoso'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{3}
					pregunta		=	"Color de ojos"
					respuestasA		=	{tipo2 == 'invierno'? [
											{opcion: 'a', valor: '1', texto:'Negro'},
											{opcion: 'a', valor: '1', texto:'Café'},
											{opcion: 'a', valor: '1', texto:'Violeta oscuro'},
											{opcion: 'a', valor: '1', texto:'Zafiro oscuro'}
										]: tipo2 == 'verano'? [
											{opcion: 'a', valor: '1', texto:'castaño claro ceniza canosos'},
											{opcion: 'a', valor: '1', texto:'Rubio Ceniza'},
											{opcion: 'a', valor: '1', texto:'Pelirrojas naturales claros'},
											{opcion: 'a', valor: '1', texto:'Canosos'}
										]: []}
					respuestasB		=	{tipo2 == 'invierno'? [
											{opcion: 'b', valor: '2', texto:'Gris verdoso con transparencia'},
											{opcion: 'b', valor: '2', texto:'azules con transparencia'},
											{opcion: 'b', valor: '2', texto:'Almendras con transparencia'},
											{opcion: 'b', valor: '2', texto:'verdes con transparencia'},
											{opcion: 'b', valor: '2', texto:'violetas con transparencia'}
										]: tipo2 == 'verano'? [
											{opcion: 'b', valor: '2', texto:'Grises Verdosos'},
											{opcion: 'b', valor: '2', texto:'Azul Grisáceo'},
											{opcion: 'b', valor: '2', texto:'Miel'},
											{opcion: 'b', valor: '2', texto:'Almendra'}
										]: []}
					respuestasC		=	{tipo2 == 'invierno'? [
											{opcion: 'c', valor: '1', texto:'Negro'},
											{opcion: 'c', valor: '1', texto:'Café'},
											{opcion: 'c', valor: '3', texto:'Marrón oscuro'},
											{opcion: 'c', valor: '3', texto:'Almendra'}
										]: tipo2 == 'verano'? [
											{opcion: 'c', valor: '3', texto:'Grises'},
											{opcion: 'c', valor: '3', texto:'Celestes'},
											{opcion: 'c', valor: '3', texto:'Verdes Grisáseos'},
											{opcion: 'c', valor: '3', texto:'Azul Claro'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>

				<Preguntas2
					id_pregunta		=	{4}
					pregunta		=	"Tus rasgos son similares a los de? "
					respuestasA		=	{tipo2 == 'invierno'? [
											{opcion: 'a', valor: '1', texto:'Demy Lovato'},
											{opcion: 'a', valor: '1', texto:'Sandra Bullock'},
											{opcion: 'a', valor: '1', texto:'Lucy Liu'},
											{opcion: 'a', valor: '1', texto:'katty perry'},
											{opcion: 'a', valor: '1', texto:'Khoudia Diop'},
											{opcion: 'a', valor: '1', texto:'Nyakim Gatwech'},
											{opcion: 'a', valor: '1', texto:'Megan Fox'}
										]: tipo2 == 'verano'? [
											{opcion: 'a', valor: '1', texto:'Amanda Seyfried'},
											{opcion: 'a', valor: '1', texto:'Marcia Cross'},
											{opcion: 'a', valor: '1', texto:'Julianne Moore'},
											{opcion: 'a', valor: '1', texto:'Lily Colen'},
											{opcion: 'a', valor: '1', texto:'Kate Winslet'}
										]: []}
					respuestasB		=	{tipo2 == 'invierno'? [
											{opcion: 'b', valor: '2', texto:'Jennifer Connelly'},
											{opcion: 'b', valor: '2', texto:'Anne Hathaway'},
											{opcion: 'b', valor: '2', texto:'Catalina de Cambrige'},
											{opcion: 'b', valor: '2', texto:'Enma Stone'},
											{opcion: 'b', valor: '2', texto:'Lily Collins'}
										]: tipo2 == 'verano'? [
											{opcion: 'b', valor: '2', texto:'Margot Robbie'},
											{opcion: 'b', valor: '2', texto:'Gigi Hadid'},
											{opcion: 'b', valor: '2', texto:'Cara Delevingne'},
											{opcion: 'b', valor: '2', texto:'Amaia salamanca'}
										]: []}
					respuestasC		=	{tipo2 == 'invierno'? [
											{opcion: 'c', valor: '3', texto:'Hally Berry'},
											{opcion: 'c', valor: '3', texto:'Zendaya'},
											{opcion: 'c', valor: '3', texto:'Alicia Keys'},
											{opcion: 'c', valor: '3', texto:'Naomi Campbell'},
											{opcion: 'c', valor: '3', texto:"lupita Nyong'o"},
											{opcion: 'c', valor: '3', texto:'Viola Davis'}
										]: tipo2 == 'verano'? [
											{opcion: 'c', valor: '3', texto:'Gwyneth Paltrow'},
											{opcion: 'c', valor: '3', texto:'Nicole Kidman'},
											{opcion: 'c', valor: '3', texto:'JENNIFER LAWRENCE'},
											{opcion: 'c', valor: '3', texto:'TAYLOR SWIFT'},
											{opcion: 'c', valor: '3', texto:'Cameron Diaz'},
											{opcion: 'c', valor: '3', texto:'Michelle Pfeiffer'}
										]: []}
					setRespuestas	=	{setRespuesta.bind(this)}/>
			</>
			}
		</div>
	)
}

const Preguntas = ({id_pregunta, pregunta, respuestas, setRespuestas}) => {

	function setRespuesta(resp) {
		setRespuestas(id_pregunta, resp);
	}

	return (
		<div className="column full">
			<div className="row full">
				<div className="curso-2 title full">
					<h3 className="title">Pregunta {id_pregunta}:</h3>
					<div className="white-space-16"></div>
					<h4 className="subtitle">{pregunta}</h4>
				</div>
				{Array.isArray(respuestas) &&
					respuestas.map((r, index) => {
						return (
							<div className="column justify-center align-center" key={index} onClick={setRespuesta.bind(this, r)}>
								<div className="container">
									<div className="card-img bg-g4 full column justify-center align-center">
										<div className="responsive-img">
											<img src={Flutter} alt="" />
										</div>
										<div className="row text-center">
											
										</div>
										<div className="white-space-16"></div>
										<div className="white-space-16"></div>
										<div className="row text-center">
											<p className="color-white font-regular">{r.texto}</p>
										</div>
										<div className="white-space-8"></div>
									</div>
								</div>
							</div>
						)
					})
				}
			</div>
			<div className="white-space-16"></div>
		</div>
	)
}

const Preguntas2 = ({id_pregunta, pregunta, respuestasA, respuestasB, respuestasC, setRespuestas}) => {

	function setRespuesta(resp) {
		setRespuestas(id_pregunta, resp);
	}

	return (
		<div className="column full">
			<div className="row full">
				<div className="curso-2 title full">
					<h3 className="title">Pregunta {id_pregunta}:</h3>
					<div className="white-space-16"></div>
					<h4 className="subtitle">{pregunta}</h4>
				</div>
				<div className="row">
					<div className="column">
						{Array.isArray(respuestasA) &&
							respuestasA.map((r, index) => {
								return (
									<div className="column justify-center align-center" key={index} onClick={setRespuesta.bind(this, r)}>
										<div className="container">
											<div className="card-section2 bg-g4 full column justify-center align-center">
												<div className="responsive-img">
													<img src={Flutter} alt="" />
												</div>
												<div className="row text-center">
													
												</div>
												<div className="white-space-16"></div>
												<div className="white-space-16"></div>
												<div className="row text-center">
													<p className="color-white font-regular">{r.texto}</p>
												</div>
												<div className="white-space-8"></div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					<div className="column">
						{Array.isArray(respuestasB) &&
							respuestasB.map((r, index) => {
								return (
									<div className="column justify-center align-center" key={index} onClick={setRespuesta.bind(this, r)}>
										<div className="container">
											<div className="card-section2 bg-g4 full column justify-center align-center">
												<div className="responsive-img">
													<img src={Flutter} alt="" />
												</div>
												<div className="row text-center">
													
												</div>
												<div className="white-space-16"></div>
												<div className="white-space-16"></div>
												<div className="row text-center">
													<p className="color-white font-regular">{r.texto}</p>
												</div>
												<div className="white-space-8"></div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
					<div className="column">
						{Array.isArray(respuestasC) &&
							respuestasC.map((r, index) => {
								return (
									<div className="column justify-center align-center" key={index} onClick={setRespuesta.bind(this, r)}>
										<div className="container">
											<div className="card-section2 bg-g4 full column justify-center align-center">
												<div className="responsive-img">
													<img src={Flutter} alt="" />
												</div>
												<div className="row text-center">
													
												</div>
												<div className="white-space-16"></div>
												<div className="white-space-16"></div>
												<div className="row text-center">
													<p className="color-white font-regular">{r.texto}</p>
												</div>
												<div className="white-space-8"></div>
											</div>
										</div>
									</div>
								)
							})
						}
					</div>
				</div>
			</div>
			<div className="white-space-16"></div>
		</div>
	)
}

const Cuestionario = () => {
	const [bloque, setBloque] = useState(1);
	const [resultado1, setResultado1] = useState(false);
	const [respuestas, setRespuestas] = useState({bloque1: null, bloque2: null, bloque3: null, bloque4: null});
	const [tipo, setTipo] = useState('');
	const [tipo2, setTipo2] = useState('');

	function sendRespuestas(resp) {
		if (Array.isArray(resp.bloque1)) {
			const res = {a: 0, b: 0};
			resp.bloque1.slice(1,4).forEach(r => {
				if(r.opcion == 'a') {
					res.a = res.a + 1
				}
				if(r.opcion == 'b') {
					res.b = res.b + 1
				}
			});
			if (res.a > res.b) {
				setTipo('a');
			}
			if (res.b > res.a) {
				setTipo('b');
			}
			if (resp.bloque1.length >= 5) {
				setResultado1(true);
			}
		}

		if (resp.bloque2) {
			const { opcion } = resp.bloque2;
			if (opcion) {
				setTipo2(opcion);
			} else {
				setTipo2('');
			}
		}

		setRespuestas(resp);
	}

	return (
		<div className="row full cursos">
			<div className="column full align-center">
				<div className="container column">
					<header className="row justify-center align-center" id="navbar">
						<div className="full justify-between">
							<div className="logo align-center row">
								<a href="#init" className="responsive-img auto">
									<img src={Logo} alt="Logo" title="Logo" />
								</a>
							</div>
							<nav className="align-center justify-end">
								<div className="nav-icon" id="nav-iconburg">
									<div></div>
								</div>

								<div className="row main" id="main-cont">
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-lightgray"> Home </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-lightgray"> Lorem ipsum </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-black"> Lorem ipsum </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-black"> Lorem ipsum </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-black"> Lorem ipsum </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-black"> Lorem ipsum </a>
									</div>
									<div className="nav-item auto">
										<a href="#init" className="font-regular color-black"> Lorem ipsum </a>
									</div>
								</div>
							</nav>
						</div>
					</header>
				</div>
				<div className="section-2 row full justify-center align-center">
					<div className="container column justify-center">
						<div className="white-space-64"></div>
						<div className="row full justify-between">
							<h1 className="title">1</h1>
							<h2 className="subtitle-line"> DEFINAMOS TU <br/> GRUPO DE COLOR </h2>
							<h1 className="title">2</h1>
							<h2 className="subtitle-line">RECONOCE EL CONTRATES <br/> DE TU PIEL</h2>
							<h1 className="title">3</h1>
							<h2 className="subtitle-line">DESCUBRE TU <br/>SUBPALETA DE COLOR</h2>
							<button className="btn-gray">RESULTADOS</button>
						</div>
						<div className="white-space-64"></div>
						{bloque == 1 && resultado1 == false?
							<PreguntasBloque1
								bloque			=	{1}
								setBloque		=	{setBloque}
								respuestas		=	{respuestas}
								sendRespuestas	=	{sendRespuestas.bind(this)}
								changeBloque	=	{() => setBloque(bloque + 1)}/>
							: (bloque == 1 && resultado1 == true) &&
							<ResultadoBloque1
								setBloque	=	{setBloque.bind(this)}/>
						}
						{bloque == 2 &&
							<PreguntasBloque2
								bloque			=	{2}
								tipo			=	{tipo}
								setBloque		=	{setBloque}
								respuestas		=	{respuestas}
								sendRespuestas	=	{sendRespuestas.bind(this)}
								changeBloque	=	{() => setBloque(bloque + 1)}/>
						}
						{bloque == 3 &&
							<PreguntasBloque3
								bloque			=	{3}
								tipo			=	{tipo}
								tipo2			=	{tipo2}
								setBloque		=	{setBloque}
								respuestas		=	{respuestas}
								sendRespuestas	=	{sendRespuestas.bind(this)}
								changeBloque	=	{() => setBloque(bloque + 1)}/>
						}
					</div>
				</div>
			</div>
		</div>
	)
}

export default Cuestionario;

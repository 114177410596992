import React from "react";
import {Consumer} from "../context";
import {withRouter} from "react-router-dom";

import Logo from "../images/cropped-Annia-Logo02.png";
import Mc4 from "../images/mc4.svg";
import Mc1 from "../images/mc1.svg";
import Mc2 from "../images/mc2.svg";
import Mc3 from "../images/mc3.svg";
import ReactNav from "../images/react-native_1x-3.svg";
import Swift from "../images/swiftui14-cover.svg";
import UI from "../images/ui-design.svg";
import Flutter from "../images/flutter-cover.svg";
import Promo from "../images/Create_a_Promo_Video_in_AE.svg";

class Landing extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			loading: false,
		};
	}

	render() {
		return (
			<div className="row full landing">
				<div class="column full align-center">
					<div class="section-1 row full justify-center">
						<div class="container column">
							<header class="row justify-center align-center" id="navbar">
								<div class="full justify-between">
									<div class="logo align-center row">
										<a href="#init" class="responsive-img auto">
											<img src={Logo} alt="Logo" title="Logo" />
										</a>
									</div>
									<nav class="align-center justify-end">
										<div class="nav-icon" id="nav-iconburg">
											<div></div>
										</div>

										<div class="row main" id="main-cont">
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-lightgray">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-lightgray">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-black">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-black">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-black">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-black">
													Lorem ipsum
												</a>
											</div>
											<div class="nav-item auto">
												<a href="#init" class="font-regular color-black">
													Lorem ipsum
												</a>
											</div>
										</div>
									</nav>
								</div>
							</header>
							<div class="row">
								<div class="white-space-64"></div>
							</div>
							<div class="row">
								<div class="white-space-64"></div>
							</div>
							<div class="row full">
								<div class="column full message-side align-start">
									<div class="row">
										<p class="message-s1 font-quadruple color-black weight-bold">
											Asesoria <br />
											de imagen & color. <br />
											Personaliza tu estilo
											<br /> imagen
										</p>
									</div>
									<div class="white-space-8"></div>
									<div class="row half">
										<p class="color-darkgray">
											Descubre que colores te favorecen y se adaptan mejor a tu imagen y estilo.El tono tu piel,cabello y ojos crean un código de color ¿Quieres descubrirlo?
										</p>
									</div>
									<div class="white-space-32"></div>
									<div className="row semi-full">
										<div className="card-price cursor-pointer column semi-full justify-center">
											<div className="row full">
												<div className="column half justify-center align-center">
													<div className="round"></div>
												</div>
												<div className="column full justify-center">
													<div className="bnt-transparent">
													<div className="row ">
														<p className="font-large weight-bold color-black"> Encuentra tu paleta de color</p>
													</div>
													<div className="row">
														<div className="white-space-8"></div>
													</div>
													<div className="row">	
														<p className="font-regular color-black">Desde $19 USD al mes.</p>
													</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="column full mockup-side">
									<div class="mockup" id="mc1">
										<img src={Mc4} alt="" />
									</div>
									<div class="mockup" id="mc2">
										<img src={Mc4} alt="" />
									</div>
									<div class="mockup" id="mc3">
										<img src={Mc1} alt="" />
									</div>
									<div class="mockup" id="mc4">
										<img src={Mc2} alt="" />
									</div>
									<div class="mockup" id="mc5">
										<img src={Mc3} alt="" />
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="section-2 row full justify-center align-center">
						<div class="container column justify-center">
							<div class="white-space-64"></div>
							<div class="white-space-64"></div>
							<div class="row full justify-center align-center">
								<div class="column full justify-center align-center">
									<div class="semi-full justify-center align-center">
										<div class="card-list full justify-center">
											<div class="column full align-center justify-center">
												<div class="container">
													<div class="card-img bg-g1 full column justify-center align-center">
														<div class="responsive-img">
															<img src={ReactNav} alt="" />
														</div>
														<div class="row text-center">
															<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
														</div>
														<div class="white-space-16"></div>
														<div class="row text-center">
															<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
														</div>
														<div class="white-space-8"></div>
														<div class="row justify-center align-center">
															<button class="btn-transparent color-gray">
																<i class="fas fa-thumbs-up"></i>
															</button>
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div class="column full justify-center align-center">
									<div class="container justify-center align-center row">
										<div class="column full">
											<div class="row">
												<p class="color- color-black font-medium">Analisis de color</p>
											</div>
											<div class="row">
												<div class="white-space-16"></div>
											</div>
											<div class="row">
												<p class="color- color-black font-huge weight-bold">Presentación</p>
											</div>
											<div class="row">
												<div class="white-space-16"></div>
											</div>
											<div class="row">
												<p class="color- color-black font-medium">
													Soy Annia y te invito a descubrir los beneficios que obtendras al descubrir la paleta de color que mejor se adapte a tu imagen y estilo.
												</p>
											</div>
											<div class="row">
												<div class="white-space-16"></div>
											</div>
											<div class="row">
												<p class="color- color-black font-medium">Si deseas mejorar tu imagen através del color, dale click y descubre lo que podemos hacer por ti!</p>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="white-space-64"></div>
							<div class="row justify-center align-center">
								<div class="column justify-center align-center">
									<div class="container">
										<div class="card-img bg-g1 full column justify-center align-center">
											<div class="responsive-img">
												<img src={ReactNav} alt="" />
											</div>
											<div class="row text-center">
												<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
											</div>
											<div class="white-space-16"></div>
											<div class="row text-center">
												<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
											</div>
											<div class="white-space-8"></div>
											<div class="row justify-center align-center">
												<button class="btn-transparent color-gray">
													<i class="fas fa-thumbs-up"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="column justify-center align-center">
									<div class="container">
										<div class="card-img bg-g2 full column justify-center align-center">
											<div class="responsive-img">
												<img src={Swift} alt="" />
											</div>
											<div class="row text-center">
												<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
											</div>
											<div class="white-space-16"></div>
											<div class="row text-center">
												<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
											</div>
											<div class="white-space-8"></div>
											<div class="row justify-center align-center">
												<button class="btn-transparent color-gray">
													<i class="fas fa-thumbs-up"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="column justify-center align-center">
									<div class="container">
										<div class="card-img bg-g3 full column justify-center align-center">
											<div class="responsive-img">
												<img src={UI} alt="" />
											</div>
											<div class="row text-center">
												<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
											</div>
											<div class="white-space-16"></div>
											<div class="row text-center">
												<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
											</div>
											<div class="white-space-8"></div>
											<div class="row justify-center align-center">
												<button class="btn-transparent color-gray">
													<i class="fas fa-thumbs-up"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="column justify-center align-center">
									<div class="container">
										<div class="card-img bg-g4 full column justify-center align-center">
											<div class="responsive-img">
												<img src={Flutter} alt="" />
											</div>
											<div class="row text-center">
												<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
											</div>
											<div class="white-space-16"></div>
											<div class="row text-center">
												<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
											</div>
											<div class="white-space-8"></div>
											<div class="row justify-center align-center">
												<button class="btn-transparent color-gray">
													<i class="fas fa-thumbs-up"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
								<div class="column justify-center align-center">
									<div class="container">
										<div class="card-img bg-g1 full column justify-center align-center">
											<div class="responsive-img">
												<img src={Promo} alt="" />
											</div>
											<div class="row text-center">
												<p class="color-white weight-bold font-large">Lorem, ipsum dolor sit amet.</p>
											</div>
											<div class="white-space-16"></div>
											<div class="row text-center">
												<p class="color-gray font-regular">Lorem, ipsum dolor.</p>
											</div>
											<div class="white-space-8"></div>
											<div class="row justify-center align-center">
												<button class="btn-transparent color-gray">
													<i class="fas fa-thumbs-up"></i>
												</button>
											</div>
										</div>
									</div>
								</div>
							</div>
							<div class="white-space-64"></div>
							<div class="row full justify-center align-center">
								<button class="btn-transparent color-gray">
									<i class="fas fa-window-restore"></i> &nbsp; lorem
								</button>
							</div>
						</div>
					</div>
					<div class="section-3 row full justify-center align-center">
						<div class="white-space-64"></div>
					</div>
					<div class="section row"></div>
					<footer className="row full justify-center align-center">
						<div className="container column">
							<div class="row logo">
								<a href="#" class="responsive-img auto">
									<img src={Logo} alt="Logo" title="Logo" />
								</a>
							</div>
							<div className="row">
								<div className="white-space-24"></div>
							</div>
							<div className="row">
								<div className="column full">
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Home
										</a>
									</div>
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Presentación
										</a>
									</div>
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											3 Etapas
										</a>
									</div>
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Precios
										</a>
									</div>
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Contactos
										</a>
									</div>
								</div>
								<div className="column full">
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Busqueda
										</a>
									</div>
									<div className="row footer-item">
										<a href="#" className="color-white font-medium weight-bold">
											Ayuda
										</a>
									</div>
								</div>
								<div className="column full">
									<div className="white-space-16"></div>
								</div>
							</div>
						</div>
					</footer>
				</div>
			</div>
		);
	}
}

export default withRouter(Consumer(Landing));

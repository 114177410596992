import React from 'react';
import Sidebar from "../components/sideBar";
import { Consumer } from '../context';

const ReactPayPal = () => {
	const [paid, setPaid] = React.useState(false);
	const [error, setError] = React.useState(null);

	const paypalRef = React.useRef();

	React.useEffect(() => {
		if (window.paypal) {
			window.paypal
			.Buttons({
				createOrder: (data, actions) => {
					return actions.order.create({
						intent: "CAPTURE",
						purchase_units: [
							{
								description: "Your description",
								amount: {
									currency_code: "INR",
									value: 500.0,
								},
							},
						],
					});
				},
				onApprove: async (data, actions) => {
					const order = await actions.order.capture();
					setPaid(true);
					console.log(order);
				},
				onError: (err) => {
					setError(err);
					console.error(err);
				},
			})
			.render(paypalRef.current);
		}
	}, []);

	// If the payment has been made
	if (paid) {
		return <div>Payment successful.!</div>;
	}
  
	// If any error occurs
	if (error) {
		return <div>Error Occurred in processing payment.! Please try again.</div>;
	}

	return (
        <div>

        </div>
    )
}

const Pagos = () => {
	const [checkout, setCheckout] = React.useState(true);

	return (
		<div className="row full view">
			<Sidebar/>
			{(checkout === true) ?
				<div className="payment-div">
					<ReactPayPal 
						total={500}
					/>
				</div>
				:
				<div>
					<h1>React-PayPal</h1>
					<button onClick={() => {setCheckout(true)}} className="checkout-button">Checkout</button>
				</div>
			}
		</div>
	)
}

export default Consumer(Pagos);
